// 话题管理相关接口
import request from '@/request/request'

// 商品列表
export const ProductPageList = (params) => request.post(`/adminv2/productPageList`, params);

// 添加商品
export const AddProduct = (params) => request.post(`/adminv2/addProduct`, params);

// 商品详情
export const ProductDetail = (params) => request.post(`/adminv2/productDetail`, params);

// 编辑商品
export const EditProduct = (params) => request.post(`/adminv2/editProduct`, params);

// 启用商品
export const EnableProduct = (params) => request.post(`/adminv2/enableProduct`, params);

// 禁用商品
export const DisableProduct = (params) => request.post(`/adminv2/disableProduct`, params);

// 新版关联商品列表
export const GetCourseList = (params) => request.post(`/adminv2/getCourseList`, params);


// 实体商品
// 添加商品分类
export const addGoodsCategoryAPI = (params) => request.post(`/adminv2/shopgoods/addCategory`, params)
// 修改商品分类
export const editGoodsCategoryAPI = (params) => request.post(`/adminv2/shopgoods/editCategory`, params)
// 获取商品分类列表
export const getGoodsCategoryListAPI = (params) => request.post(`/adminv2/shopgoods/categoryLists`, params)
// 分类是否展示
export const removedGoodsCategoryAPI = (params) => request.post(`/adminv2/shopgoods/removedCategory`, params)
// 删除商品分类
export const delGoodsCategoryAPI = (params) => request.post(`/adminv2/shopgoods/delCategory`, params)
// 商品列表
export const getGoodsListAPI = (params) => request.post(`/adminv2/shopgoods/lists`, params)
// 添加商品
export const addGoodsAPI = (params) => request.post(`/adminv2/shopgoods/add`, params)
// 修改商品
export const editGoodsAPI = (params) => request.post(`/adminv2/shopgoods/edit`, params)
// 商品详情
export const getGoodsDetailAPI = (params) => request.post('/adminv2/shopgoods/getOne', params)
// 商品下架
export const removedGoodsAPI = (params) => request.post('/adminv2/shopgoods/removed', params)
// 商品销售详情
export const getGoodsOrderInfoAPI = (params) => request.post('/adminv2/shopgoods/goodsOrderInfo', params)
// 商品分类 一级分类-二级分类
export const getCategoryLinkAPI = (params) => request.post('/adminv2/shopgoods/categoryLink', params)
