<template>
    <div class="padding24" id="third_Party_order">
        <a-spin :spinning="spinning" size="large" :tip="tip">
            <div class="isDisplay" v-show="$route.meta.isShow">
                <div class="top">
                    <a-form layout="inline">
                        <a-form-item>
                            <a-input v-model="form.productName" placeholder="请输入名称" />
                        </a-form-item>
                        <a-form-item>
                            <a-select v-model="form.productType" allowClear placeholder="商品类型" style="width: 120px">
                                <a-select-option :value="1">课程</a-select-option>
                                <a-select-option :value="2">会员</a-select-option>
                            </a-select>
                        </a-form-item>
<!--                        <a-form-item>-->
<!--                            <a-select v-model="form.rightsType" placeholder="权益类型" style="width: 120px">-->
<!--                                <a-select-option :value="1">正式</a-select-option>-->
<!--                                <a-select-option :value="2">体验</a-select-option>-->
<!--                            </a-select>-->
<!--                        </a-form-item>-->
                        <a-form-item>
                            <a-select v-model="form.enableFlag" allowClear placeholder="是否可用" style="width: 120px">
                                <a-select-option :value="1">是</a-select-option>
                                <a-select-option :value="0">否</a-select-option>
                                <a-select-option :value="-1">全部</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item>
                            <a-select v-model="form.agentPlatform" allowClear placeholder="渠道销售类型" style="width: 140px">
                                <a-select-option :value="1">第三方平台</a-select-option>
                                <a-select-option :value="2">代理商</a-select-option>
                                <a-select-option :value="3">总部直销</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item>
                            <a-button icon="search" class="margin_right20" type="primary" @click="getInitFn">查询</a-button>
                            <a-button v-if="isShowAdd" icon="plus" class="margin_right20" type="primary" @click="addClick">添加</a-button>
                            <a-button @click="getInitFnExport">导出</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <!-- 表格 -->
                <a-table :columns="columns" :data-source="tableData" :pagination="false" :row-key="record => record.id"  :loading="tableLoading">
                    <span slot="productType" slot-scope="productType, record">
                        <span>{{record.productType == 1 ? '课程' : '会员'}}</span>
                    </span>
<!--                    <span slot="rightsType" slot-scope="rightsType, record">-->
<!--                        <span>{{record.rightsType == 1 ? '正式' : '体验'}}</span>-->
<!--                    </span>-->
                    <span slot="agentPlatform" slot-scope="agentPlatform, record">
                        <span>{{record.agentPlatform == 1 ? '第三方平台' : record.agentPlatform == 2 ? '代理商' : '总部直销'}}</span>
                    </span>
                    <span slot="changeAgent" slot-scope="changeAgent, record">
                        <span>{{record.changeAgent == 1 ? '更改' : '不更改'}}</span>
                    </span>
                    <span slot="useCondition" slot-scope="useCondition, record">
                        <span>{{record.useCondition == 1 ? '无限制' : record.useCondition == 2 ? '无会期记录用户' : '无课程正式权益记录用户可用'}}</span>
                    </span>
                    <span slot="useAgain" slot-scope="useAgain, record">
                        <span>{{record.useAgain == 1 ? '是' : '否'}}</span>
                    </span>
                    <span slot="enableFlag" slot-scope="enableFlag, record">
                        <span>{{record.enableFlag == 1 ? '是' : '否'}}</span>
                    </span>
                    <span slot="useType" slot-scope="useType, record">
                        <span>{{record.useType == 1 ? '售卖' : '赠送'}}</span>
                    </span>
                    <span slot="operation" slot-scope="operation, record">
                        <a id="copy_memberNo" class="margin_right10" type="primary" @click="editClick(record)">编辑</a>
                        <template>
                            <template v-if="record.enableFlag == 0 && isShowEnable">
                                <a-popconfirm title="确认要启用此商品吗？" ok-text="确定" cancel-text="取消" @confirm="releaseClick(record)">
                                    <a id="copy_memberNo" type="primary" class="margin_right10">启用</a>
                                </a-popconfirm>
                            </template>
                            <template v-if="record.enableFlag == 1 && isShowDisable">
                                <a-popconfirm title="确认要禁用此商品吗？" ok-text="确定" cancel-text="取消" @confirm="disableClick(record)">
                                    <a id="copy_memberNo" type="primary" class="margin_right10">禁用</a>
                                </a-popconfirm>
                            </template>
                        </template>
                        <a id="copy_memberNo" v-if="isShowAdd" class="margin_right10" type="primary" @click="copyEditClick(record)">复制在编辑</a>
                    </span>
                </a-table>
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
                <!-- 返回顶部 -->
                <a-back-top :target="targetFn" :visibilityHeight="100"/>

                <a-modal v-model="visibleShow" title="导出">
                    <template #footer>
                        <div style="display: flex;justify-content: flex-end">
                            <a-button key="back" class="margin_right10" @click="visibleShow = false">返回</a-button>
                            <download-excel
                                class = "export-excel-wrapper"
                                :data = "tableDataExport"
                                :fields = "tableDataName"
                                name = "渠道直销商品.xls">
                                <a-button key="submit" type="primary" @click="visibleShow = false">确定</a-button>
                            </download-excel>
                        </div>
                    </template>
                    <p>
                        <span>确定导出搜索的数据吗？</span>
                    </p>
                </a-modal>
            </div>
        </a-spin>
        <router-view />
    </div>
</template>

<script>
import moment from "moment";
import {ProductPageList, DisableProduct, EnableProduct} from "@/request/api/physicalCard";
import MyPagination from "@/components/pagination/MyPagination";
import {message,popconfirm} from "ant-design-vue";
import {codeFn} from "@/utils/tools";

export default {
    components: {MyPagination},
    created() {
        this.getInitFn();
        if (codeFn("/adminv2/addProduct")) this.isShowAdd = true;
        if (codeFn("/adminv2/editProduct")) this.isShowEdit = true;
        if (codeFn("/adminv2/enableProduct")) this.isShowEnable = true;
        if (codeFn("/adminv2/disableProduct")) this.isShowDisable = true;
    },
    data() {
        return {
            visibleShow:false,

            isShowAdd:false,
            isShowEdit:false,
            isShowEnable:false,
            isShowDisable:false,

            form:{
                productName:'',
                productType:undefined,
                // rightsType:undefined,
                enableFlag:undefined,
                agentPlatform:undefined,
            },

            tip: "",
            tableLoading: true,
            spinning: false, // 全局loading
            pageNo: 1,
            pageSize: 20,
            count: 0,

            columns: [
                {
                    title: "渠道直销商品名",
                    dataIndex: "productName",
                    key: "productName",
                },
                {
                    title: "商品类型",
                    dataIndex: "productType",
                    key: "productType",
                    scopedSlots: {customRender: "productType"},
                },
                // {
                //     title: "权益类型",
                //     dataIndex: "rightsType",
                //     key: "rightsType",
                //     scopedSlots: {customRender: "rightsType"},
                // },
                {
                    title: "渠道销售类型",
                    dataIndex: "agentPlatform",
                    key: "agentPlatform",
                    scopedSlots: {customRender: "agentPlatform"},
                },
                {
                    title: "用户归属限制",
                    dataIndex: "changeAgent",
                    key: "changeAgent",
                    scopedSlots: {customRender: "changeAgent"},
                },
                {
                    title: "用户使用限制",
                    dataIndex: "useCondition",
                    key: "useCondition",
                    scopedSlots: {customRender: "useCondition"},
                },
                {
                    title: "是否重复使用",
                    dataIndex: "useAgain",
                    key: "useAgain",
                    scopedSlots: {customRender: "useAgain"},
                },
                {
                    title: "是否可用",
                    dataIndex: "enableFlag",
                    key: "enableFlag",
                    scopedSlots: {customRender: "enableFlag"},
                },
                {
                    title: "商品用途",
                    dataIndex: "useType",
                    key: "useType",
                    scopedSlots: {customRender: "useType"},
                },
                {
                    title: "操作",
                    fixed: "right",
                    width: 190,
                    key: "operation",
                    dataIndex: "operation",
                    scopedSlots: {customRender: "operation"},
                },
            ],
            tableData: [],
            tableDataExport:[],
            //导出用 设置表头数据
            tableDataName: {
                "渠道直销商品名": "productName",
                "商品类型": {
                    field: "productType",
                    callback: value => {
                        return value == 1 ? '课程' : '会员'
                    }
                },
                "渠道销售类型": {
                    field: "agentPlatform",
                    callback: value => {
                        return value == 1 ? '第三方平台' : value == 2 ? '代理商' : '总部直销'
                    }
                },
                "用户归属限制": {
                    field: "changeAgent",
                    callback: value => {
                        return value == 1 ? '更改' : '不更改'
                    }
                },
                "用户使用限制": {
                    field: "useContdition",
                    callback: value => {
                        return value == 1 ? '无限制' : '无会期记录用户'
                    }
                },
                "是否重复使用": {
                    field: "useAgain",
                    callback: value => {
                        return value == 1 ? '能' : '否'
                    }
                },
                "是否可用": {
                    field: "enableFlag",
                    callback: value => {
                        return value == 1 ? '能' : '否'
                    }
                },
                "商品用途": {
                    field: "useType",
                    callback: value => {
                        return value == 1 ? '售卖' : '赠送'
                    }
                },
            },
        };
    },

    watch: {
        $route(to) {
            if (to.path === "/physicalCard/physicalCardList") {
                this.getInitFn();
            }
        },
    },

    methods: {
        moment,

        // 点击添加按钮
        addClick() {
            this.$router.push("/physicalCard/physicalCardList/physicalCardEdit/0/0")
        },

        // 复制在编辑
        copyEditClick(record){
            this.$router.push(`/physicalCard/physicalCardList/physicalCardEdit/${record.id}/2`)
        },

        // 点击编辑按钮
        editClick(record) {
            this.$router.push(`/physicalCard/physicalCardList/physicalCardEdit/${record.id}/1`)
        },

        // 点击上架按钮
        releaseClick(record) {
            EnableProduct({
                id:record.id,
            }).then(({ code, data }) => {
                if(code == 200){
                    message.success('已启用')
                    this.getInitFn()
                }else{
                    message.success(data.msg)
                }
            });
        },

        // 点击下架按钮
        disableClick(record) {
            DisableProduct({
                id:record.id,
            }).then(({ code, data }) => {
                if(code == 200){
                    message.success('已禁用')
                    this.getInitFn()
                }else{
                    message.success(data.msg)
                }
            });
        },

        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getInitFn();
        },

        // 原属数据获取
        getInitFn() {
            this.tableLoading = true
            ProductPageList({
                productName:this.form.productName,
                productType:this.form.productType,
                // rightsType:this.form.rightsType,
                enableFlag:this.form.enableFlag,
                agentPlatform:this.form.agentPlatform,
                pageSize:this.pageSize,
                pageNo:this.pageNo
            }).then(({ code, data }) => {
                this.tableLoading = false
                this.tableData = data.list;
                this.count = data.count
            });
        },

        // 导出原属数据
        getInitFnExport() {
            this.visibleShow = true
            ProductPageList({
                productName:this.form.productName,
                productType:this.form.productType,
                enableFlag:this.form.enableFlag,
                agentPlatform:this.form.agentPlatform,
                pageSize:0,
                pageNo:0
            }).then(({ code, data }) => {
                this.tableDataExport = data.list;
            });
        },

        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#third_Party_order");
        },
    },
};
</script>

<style lang="less" scoped>
#third_Party_order {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.top {
    .top_line {
        margin-bottom: 20px;
        display: flex;

        span {
            margin-right: 20px;

            /deep/ .ant-input {
                width: 220px;
            }

            /deep/ .ant-select-selection {
                width: 110px;
                margin-left: 10px;
            }

            /deep/ .ant-calendar-picker-input {
                margin-left: 10px;
                width: auto;
            }

            /deep/ .ant-calendar-picker {
                width: 400px !important;
            }
        }

        .ant-btn {
            margin-right: 20px;
        }
    }
}
</style>
