<template>
  <div class="pagination">
    <a-pagination
      v-model="current"
      :page-size-options="pageSizeOptions"
      :total="count"
      :show-total="(count, range) => `总共${count}条`"
      :page-size="pageSize"
      showQuickJumper
      :showSizeChanger="showSizeChanger"
      @showSizeChange="onShowSizeChange"
      @change="onchange"
    >
      <template slot="buildOptionText" slot-scope="props">
        <span v-if="props.value !== '99'">{{ props.value }}条/页</span>
      </template>
    </a-pagination>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      defaule: 0,
    },
    pageNo: {
      type: Number,
      default: 1,
    },
    showSizeChanger: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      pageSizeOptions: ["20", "30", "40", "50", "100", "200"],
      current: 1,
      pageSize: 20,
    };
  },
  watch: {
    pageNo(val) {
      this.current = val
    }
  },
  created() {
    this.current = this.pageNo;
  },
  methods: {
    // pageSize 变化的回调
    onShowSizeChange(current, pageSize) {
      this.current = 1;
      this.$emit("showSizeChangeFn", current, pageSize);
      this.pageSize = pageSize;
    },
    // 分页改变的回调
    onchange(current, pageSize) {
      this.$emit("showSizeChangeFn", current, pageSize);
    },
  },
};
</script>

<style lang="less" scoped>
.pagination {
    position: relative;
    //padding-bottom: 20px;
    bottom: 0;
    text-align: right;
    margin-top: 15px;
}
</style>
